@keyframes scan {
  from {
    background-color: white;
  }
  10% {
    background-color: hsl(206, 100%, 66%);
  }
  to {
    background-color: white;
  }
}

.QrWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  & > section {
    flex-grow: 1;
    max-height: 70vh;
    max-width: 70vmin;
  }
 video {
     flex-grow: 1;
     max-height: 75vh;
     max-width: 75vmin;
 }

  &[animate="1"] {
    animation: scan 1.3s cubic-bezier(0.23, 1.06, 0.48, 0.7);
  }
}
