.Tab {
  flex-basis: 0%;
  flex-grow: 1;

  // Regular
  // border-top-right-radius: 20px;
  // border-top-left-radius: 20px;

   

  //Sideways  
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;

  width: 100%;


  span{
      writing-mode:vertical-rl;
      transform: rotate(180deg);
  }
}

.Tab:disabled {
  color: black;
  background-color: hsl(0, 0%, 83%);
}

.Tab:not(:disabled) {
  background-color: hsl(0, 0%, 63%);
}

.Tab:active {
  background-color: hsl(0, 0%, 90%);
}

.Tabs {
  
  flex-direction: column;
}
