svg {
  display: inline-block;
  margin: auto;
}

.qr {
  display: flex;
  align-items: center;
  justify-content: center;
}
