@use "sass:math";

textarea {
  resize: none;
  outline: black 1px solid;
}

textarea:focus {
  outline: blue 1px solid;
}

@mixin twoThirdsLeft {
  display: grid;
  grid-row: span 3;
  grid-column: span 4;
  grid-auto-rows: minmax(0, 1fr);
}

.grid {
  // @include twoThirdsLeft();
  display: grid;
  grid-row: span 3;
  grid-auto-rows: minmax(0, 1fr);
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.endgameOptions {
  @include twoThirdsLeft();
  grid-template-columns: 1fr 1fr;
}

// Must be odd, because one slot is taken up by an empty tag for spacing
$qualCounterSlots: 7;

.qualitativeTeam {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0px;
  min-height: 10%;

  border-top: 0.175em solid silver;

  display: flex;

  flex-direction: column;

  gap: 10%;

  .qualCounter {
    flex-basis: 0;
    flex-grow: 1;
    flex-shrink: 1;
    min-height: 25%;

    display: flex;
    flex-direction: column;

    flex-wrap: wrap;

    & > * {
      flex-grow: 1;
      flex-basis: 0;
      //min-height: 10%;
    }

    & > .attribute {
      flex-basis: 100%;
      text-align: center;
      font-size: 1.2rem;
      max-width: 25%;
    }
  }

  #teamNumber {
    font-weight: bold;
    font-size: 2.4rem;
    flex-basis: 0;
    flex-shrink: 1;
    margin: 0;
    min-height: 25%;
  }

  button {
    text-align: center;
    //font-weight: bold;

    grid-column: span 1;
    border-radius: 25vh;
    margin-bottom: 0.4vh;
    margin: 0;
  }

  .notIOS {
    font-size: 3rem;
  }

  .IOS {
    font-size: 1.7rem;
  }

  p {
    font-size: 1.6rem;
    text-align: center;
  }
}

.evenFlex {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
}

.evenFlex > * {
  flex-grow: 1;
  width: 0;
}

select,
input[type="number"],
input[type="text"],
textarea {
  padding: 2px 2vw;
}

@mixin center {
  display: flex;
  align-items: center;
  justify-content: center;
}

p,
label,
div.Center {
  @include center();
}

div.Divider {
  @include center();
  text-decoration: underline;
  font-weight: bold;
  font-size: 1.4rem;
}

label.AlignRight {
  justify-content: right;
}

label.Checkbox {
  justify-content: flex-start;
  user-select: none;

  & > input {
    margin-right: 10vw;
    margin-left: 5vw;
  }

  &[warn="1"] {
    background-color: hsl(354, 100%, 95%);
  }
}

button {
  user-select: none;
}

select {
  color: black;
  border: 2px solid black;
  -webkit-appearance: menulist;
}

input[type="checkbox"] {
  -webkit-appearance: checkbox;
}

.timer {
  border: 2px solid hsl(133, 100%, 26%);
  // makes padding the same as buttons
  padding-top: 1px;
  padding-bottom: 1px;
}

div.Numbers,
div.TextLine {
  display: flex;
  gap: 1vw;
  padding-left: 3vw;

  & > input {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 60%;
    width: 0px;
  }

  & > label {
    // No wrap previously, but I think wrap looks better?
    // Makes it so that the input can grow horizontally
    white-space: wrap;
    // scss thinks we want pre-compile min so we need to bypass it
    flex-basis: unquote("min(25vw, 100px)");
  }
}

div.TripleTeamSelector {
  display: flex;
  gap: 1vw;
  padding-left: 3vw;

  .teams {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0px;
    width: 0px;

    & > input {
      flex-grow: 1;
      flex-shrink: 1;
    }
  }

  & > label {
    white-space: nowrap;
    // scss thinks we want pre-compile min so we need to bypass it
    flex-basis: unquote("min(25vw, 100px)");
  }
}

.center-text {
  text-align: center;
}

.imageClick {
  position: relative;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  img {
    max-width: 100%;
    max-height: 100%;
    margin: auto;
  }

  .imageLocation {
    position: absolute;
    color: white;
    font-size: 1.75rem;
    font-weight: bold;
  }
}

.display {
  display: flex;
  justify-content: center;
  align-items: center;
}

.overlay {
  margin: auto;

  width: 100vw;
  height: 100vh;
  position: absolute;

  //overflow: hidden;

  button {
    position: absolute;

    //overflow: hidden;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 3rem;
    border-radius: 2rem;
  }
}

.flipped {
  transform: scale(-1);
}
