body,
html,
#root,
.App {
  margin: 0;
  padding: 0;
  height: 100%;
  touch-action: manipulation;
}

input,
textarea,
button,
select {
  -webkit-appearance: none;
  border-radius: 0;
}
