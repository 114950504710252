@keyframes pwachange {
  from {
    background-color: hsl(206, 60%, 90%);
  }
  10% {
    background-color: hsl(206, 100%, 80%);
  }
  to {
    background-color: hsl(206, 60%, 90%);
  }
}

.hidden {
  display: none;
}

div.PWA {
  &.hidden {
    display: none;
  }
  display: flex;
  & > button#Settings {
    position: absolute;
    // this places the cog in the right part of the parent
    top: 1vmin;
    right: 1vmin;
    $size: min(12vmin, 70px);
    width: $size;
    height: $size;
    font-size: 10vmin;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px;
    & > svg {
      margin: 1vmin;
    }
  }
  & > button.blue {
    flex-grow: 1;
    font-size: 1.5rem;
    white-space: pre-line;
    &:disabled {
      background-color: hsl(206, 60%, 90%);
      &[animate="1"] {
        animation: pwachange 1.3s cubic-bezier(0.23, 1.06, 0.48, 0.7);
      }
    }
    background-color: hsl(206, 100%, 76%);
    &:active:enabled {
      background-color: hsl(206, 100%, 56%);
    }
  }
}
