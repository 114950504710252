.qualCounter {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  
    width: 100%;
    height: 50%;
    
  }
  
  .qualCounter > * {
    flex-grow: 1;
    flex-basis: 0;
    //min-height: 10%;
  }
  
  .increment {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }
  
  .qualTeam {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    text-align: center;
  
    > * {
      flex-grow: 1;
      flex-basis: 0;
      //min-height: 10%;
    }
    
  }