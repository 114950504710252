@mixin activeTransition {
  transition: background-color 0.5s cubic-bezier(0.37, 1.14, 0.53, 0.91);
  &:active {
    transition: background-color 0.1s cubic-bezier(0.17, 0.67, 0.11, 1.38);
  }
}

// maybe find better fix, I want div to have same colors
button, div {
  @include activeTransition;

  color: black;
  font-size: 1rem;

  @mixin disabledColor {
    &:disabled {
      background-color: hsl(0, 0%, 90%);
    }
  }

  &.red {
    background-color: hsl(354, 100%, 85%);
    border: 2px solid hsl(354, 100%, 25%);
    &:active {
      background-color: hsl(354, 100%, 65%);
    }
    @include disabledColor();
  }

  &.less-red {
    background-color: hsl(354, 100%, 93%);
    border: 2px solid hsl(354, 100%, 25%);
    &:active {
      background-color: hsl(354, 100%, 73%);
    }
    @include disabledColor();
  }

  &.green {
    background-color: hsl(133, 100%, 86%);
    border: 2px solid hsl(133, 100%, 26%);
    &:active {
      background-color: hsl(133, 100%, 66%);
    }
    @include disabledColor();
  }

  &.less-green {
    background-color: hsl(133, 100%, 93%);
    border: 2px solid hsl(133, 100%, 26%);
    &:active {
      background-color: hsl(133, 100%, 73%);
    }
    @include disabledColor();
  }

  &.blue {
    background-color: hsl(206, 100%, 86%);
    border: 2px solid hsl(206, 100%, 26%);
    &:active {
      background-color: hsl(206, 100%, 66%);
    }
    @include disabledColor();
  }

  &.less-blue {
    background-color: hsl(206, 100%, 93%);
    border: 2px solid hsl(206, 100%, 26%);
    &:active {
      background-color: hsl(206, 100%, 73%);
    }
    @include disabledColor();
  }

  &.yellow {
    background-color: hsl(63, 100%, 86%);
    border: 2px solid hsl(63, 100%, 26%);
    &:active {
      background-color: hsl(63, 100%, 66%);
    }
    @include disabledColor();
  }

  &.less-yellow {
    background-color: hsl(63, 100%, 93%);
    border: 2px solid hsl(63, 100%, 26%);
    &:active {
      background-color: hsl(63, 100%, 73%);
    }
    @include disabledColor();
  }

  &.purple {
    background-color: hsl(280, 100%, 86%);
    border: 2px solid hsl(280, 100%, 26%);
    &:active {
      background-color: hsl(280, 100%, 66%);
    }
    @include disabledColor();
  }

  &.less-purple {
    background-color: hsl(280, 100%, 93%);
    border: 2px solid hsl(280, 100%, 26%);
    &:active {
      background-color: hsl(280, 100%, 73%);
    }
    @include disabledColor();
  }

  &.transparent{
    background-color: hsla(251, 65%, 15%, 0.75);
    border: 2px solid hsl(251, 65%, 25%);
    &:active {
      background-color: hsl(251, 65%, 50%);
    }
    @include disabledColor();
  }
    @include disabledColor();
}
