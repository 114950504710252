@use "sass:color";
@use "inputs/buttons.scss";

$tab-size: 2em;

$outline-thickness: 0.2em;

$padding-horizontal: 0.65em;
$padding-vertical: 1em;

$violet: #cc99c9;
$blue: #9ec1cf;
$green: #9ee09e;
$yellow: #fdfd97;
$orange: #feb144;
$red: #ff6663;

@mixin highlightType($type, $color) {
  &.#{$type} {
    background-color: $color;
    border: $outline-thickness solid color.scale($color, $lightness: -40%);
    @include buttons.activeTransition;
    &:active:not(:disabled) {
      background-color: color.scale($color, $lightness: 40%);
    }
    &:disabled {
      background-color: color.scale($color, $lightness: 60%);
    }
  }
}

.path {
  max-height: min-content;
  height: min-content;
}

.DataEditor {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  overflow-y: scroll;
  grid-column: span 3;
  grid-row: span 4;
}

.ElementEditor {
  grid-column: span 3;
  display: flex;
  & > p:first-child {
    margin: 3vw;
  }
}

.label {
  // type colors
  @include highlightType(Object, $blue);
  @include highlightType(String, $orange);
  @include highlightType(Number, $violet);
  @include highlightType(Boolean, $green);
  @include highlightType(Null, $red);
  @include highlightType(Undefined, $red);
  // end type colors

  // &.Object > p:first-child {
  //   padding-right: $padding-vertical;
  // }

  padding-left: $padding-horizontal;
  padding-right: $padding-horizontal;
  text-align: left;

  margin: 0;
  padding-top: $padding-vertical;
  padding-bottom: $padding-vertical;
  user-select: none;
}

.labelGroup {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  & :not(:first-child) {
    margin-left: $tab-size;
  }
  &.hidden :not(:first-child) {
    display: none;
  }
}
